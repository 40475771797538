function GetQueryString (url,name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  //截取#前的url
  console.log(dz_url,'dz_url',url);
  var dz_url = url.split('#')[1];
  console.log(dz_url,'dz_url');
    var query = url.substr(url.lastIndexOf("?") + 1);
    var r = query.match(reg); //search,查询？后面的参数，并匹配正则
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}

Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1,                 //月份
    'd+': this.getDate(),                    //日
    'h+': this.getHours(),                   //小时
    'm+': this.getMinutes(),                 //分
    's+': this.getSeconds(),                 //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    'S': this.getMilliseconds()             //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

Date.prototype.format_cus = function () {
  var thisYear = new Date().getFullYear()
  if (thisYear != this.getFullYear()) {
    return this.format('yyyy-MM-dd hh:mm')
  }
  return this.format('MM-dd hh:mm')
}
// 无感删除路由参数
function replaceUrl(url,aParam) { 
  aParam.forEach(item => {
    let fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
    if (fromindex !== -1) {
        // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
        let startIndex = url.indexOf('=', fromindex)
        let endIndex = url.indexOf('&', fromindex)
        let hashIndex = url.indexOf('#', fromindex)
        console.log(startIndex,endIndex,hashIndex,'hashIndex');
        let reg;
        if (endIndex !== -1) { // 后面还有search参数的情况
            const num = endIndex - startIndex
            reg = new RegExp(`${item}=.{${num}}`)
            url = url.replace(reg, '')
        } else if (hashIndex !== -1) { // 有hash参数的情况
            const num = hashIndex - startIndex - 1
            reg = new RegExp(`&?${item}=.{${num}}`)
            url = url.replace(reg, '')
            console.log(url,'urlurlurl');
        } else { // search参数在最后或只有一个参数的情况
            reg = new RegExp(`&?${item}=.+`)
            url = url.replace(reg, '')
        }
    }
  });
  const noSearchParam = url.indexOf('=')
  if( noSearchParam === -1 ){
      url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
  }
  return url
}
function  getFileType(url){
    let index = url.lastIndexOf('?')
    let newFileInOssPath =  url.substring(0, url.indexOf("?"))
    if (index == -1){
        newFileInOssPath = url
    }
    const fileType = newFileInOssPath.substring(newFileInOssPath.lastIndexOf('.'));
    if (fileType.toLowerCase() === '.mp4' || fileType.toLowerCase() === '.mov' || fileType.toLowerCase() === '.movc') {
        return 'VIDEO'
    } else if (fileType.toLowerCase() === '.html') {
        return 'HTML'
    } else if (fileType.toLowerCase() === '.png' || fileType.toLowerCase() === '.jpg' || fileType.toLowerCase() === '.jpeg' || fileType.toLowerCase() === '.gif') {
        return 'IMAGE'
    } else if (fileType.toLowerCase() === '.docx'){
        return 'DOC'
    } else if (fileType.toLowerCase() === '.ppt' || fileType.toLowerCase() === '.pptx'){
        return 'PPT'
    }  else if (fileType.toLowerCase() === '.pdf'){
        return 'PDF'
    } else if (fileType.toLowerCase() === '.txt'){
        return 'TXT'
    } else {
        return 'OTHER'
    }
}
function timeAgo(time) {
    var timestamp = Date.parse(time.replace(/-/gi, '/')) / 1000; // 兼容iOS Safari时间格式

    var mistiming = Math.round((new Date().getTime() / 1000 - timestamp) / 60);
    if (mistiming <= 0){
        mistiming = 1;
    }
    if (mistiming < 60) {
        return mistiming + '分钟前';
    }
    var timing = Math.round(mistiming / 60);
    if (timing < 24) {
        return timing + '小时前';
    }
    var timingday = Math.round(timing / 24);
    if (timingday < 30) {
        return timingday + '天前';
    } else if (timingday > 30 && timingday < 365) {
        return Math.round(timingday / 30) + '个月前';
    } else {
        return Math.round(timingday / 365) + '年前';
    }
}
export const isUseOpenData = () => {
    let bool = false;
    bool = sessionStorage.getItem('isWeChat') == 'true' ? true : false;
    return bool
}
export const isUtf8 = (filePart) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsText(filePart)
        fileReader.onload = (e) => {
            const str = e.target.result
            // roughly half
            const sampleStr = str.slice(4, 4 + str.length / 2)
            if (sampleStr.indexOf("�") === -1) {
                resolve('utf-8')
            } else {
                resolve('gb2312')
            }
        }
        fileReader.onerror = () => {
            reject(new Error("Failed to read the content of the file, please check whether the file is damaged"))
        }
    })
}
function newInitWaterMark(elId, textValue) {
    //默认设置
    var defaultSettings = {

        watermark_txt: "",

        watermark_x: 0, //水印起始位置x轴坐标

        watermark_y: 0, //水印起始位置Y轴坐标

        watermark_rows: 0, //水印行数

        watermark_cols: 0, //水印列数

        watermark_x_space: 40, //水印x轴间隔

        watermark_y_space: 60, //水印y轴间隔

        watermark_color: "black", //水印字体颜色

        watermark_alpha: 0.3, //水印透明度

        watermark_fontsize: "12px", //水印字体大小

        watermark_font: "微软雅黑", //水印字体

        watermark_width: 100, //水印宽度

        watermark_height: 60, //水印长度

        watermark_angle: 20 //水印倾斜度数

    };
    let textWatermarkValue = textValue;
    if (textWatermarkValue) {
        defaultSettings.watermark_txt = textWatermarkValue;
    }
    if (!textWatermarkValue) {
        return;
    }

    //采用配置项替换默认值，作用类似jquery.extend

    if (arguments.length === 1 && typeof arguments[0] === "object") {

        // 获取参数配置

        var src = arguments[0];

        for (let key in src) {

            if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key])

                continue;

            else if (src[key])

                defaultSettings[key] = src[key];

        }

    }

    var oTemp = document.createDocumentFragment();

    //获取页面最大宽度

    var page_width = Math.max(document.getElementById(elId).scrollWidth, document.getElementById(elId).clientWidth);

    var cutWidth = page_width * 0.0150;

    page_width = page_width - cutWidth;

    //获取页面最大高度
    var page_height = document.getElementById(elId).scrollHeight;
    console.log(page_height, page_width);

    // var page_height = Math.max(document.body.scrollHeight, document.body.clientHeight);

    // var page_height = document.body.scrollHeight+document.body.scrollTop;

    //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔

    if (defaultSettings.watermark_cols == 0 || (parseInt(defaultSettings.watermark_x + defaultSettings

        .watermark_width * defaultSettings.watermark_cols + defaultSettings.watermark_x_space * (

        defaultSettings.watermark_cols - 1)) > page_width)) {

        defaultSettings.watermark_cols = parseInt((page_width - defaultSettings.watermark_x + defaultSettings

            .watermark_x_space) / (defaultSettings.watermark_width + defaultSettings

            .watermark_x_space));

        defaultSettings.watermark_x_space = parseInt((page_width - defaultSettings.watermark_x -

            defaultSettings

                .watermark_width * defaultSettings.watermark_cols) / (defaultSettings.watermark_cols - 1));

    }

    //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔

    if (defaultSettings.watermark_rows == 0 || (parseInt(defaultSettings.watermark_y + defaultSettings

        .watermark_height * defaultSettings.watermark_rows + defaultSettings.watermark_y_space * (

        defaultSettings.watermark_rows - 1)) > page_height)) {

        defaultSettings.watermark_rows = parseInt((defaultSettings.watermark_y_space + page_height -

            defaultSettings

                .watermark_y) / (defaultSettings.watermark_height + defaultSettings.watermark_y_space));

        defaultSettings.watermark_y_space = parseInt(((page_height - defaultSettings.watermark_y) -

            defaultSettings

                .watermark_height * defaultSettings.watermark_rows) / (defaultSettings.watermark_rows - 1));

    }

    var x;
    var y;
    for (var i = 0; i < defaultSettings.watermark_rows; i++) {
        y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
        for (var j = 0; j < defaultSettings.watermark_cols; j++) {
            x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
            var mask_div = document.createElement("div");

            mask_div.id = "mask_div" + i + j;
            mask_div.className = "mask_div";
            mask_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
            mask_div.style.visibility = "";
            mask_div.style.position = "absolute";
            //奇偶行错开，这样水印就不对齐，显的不呆板
            mask_div.style.left = x + 20 + "px";
            mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));

            mask_div.style.top = y + 20 + "px";

            mask_div.style.overflow = "hidden";

            mask_div.style.pointerEvents = "none"; //让水印不遮挡页面的点击事件

            mask_div.style.opacity = defaultSettings.watermark_alpha;

            mask_div.style.fontSize = defaultSettings.watermark_fontsize;

            mask_div.style.fontFamily = defaultSettings.watermark_font;

            mask_div.style.color = defaultSettings.watermark_color;

            mask_div.style.textAlign = "center";

            mask_div.style.width = defaultSettings.watermark_width + "px";

            mask_div.style.height = defaultSettings.watermark_width + "px";

            mask_div.style.display = "block";

            mask_div.style.zIndex = '99';

            mask_div.style.wordBreak = 'break-all'

            oTemp.appendChild(mask_div);
        }
    }
    document.getElementById(elId).appendChild(oTemp);
}
export {
  GetQueryString,
  replaceUrl,
    getFileType,
    timeAgo,
    newInitWaterMark
}
